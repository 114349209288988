// This is a generated file - DO NOT EDIT DIRECTLY

export const icons = {
  "abv":` &:before{ content: "\\f101"; } `,
  "add":` &:before{ content: "\\f102"; } `,
  "advanced-notes":` &:before{ content: "\\f103"; } `,
  "alcohol":` &:before{ content: "\\f104"; } `,
  "alert-circle-filled":` &:before{ content: "\\f105"; } `,
  "alert-circle":` &:before{ content: "\\f106"; } `,
  "alert-triangle":` &:before{ content: "\\f107"; } `,
  "amex":` &:before{ content: "\\f108"; } `,
  "app-subscription":` &:before{ content: "\\f109"; } `,
  "apple-pay":` &:before{ content: "\\f10a"; } `,
  "armoa":` &:before{ content: "\\f10b"; } `,
  "arrow-left":` &:before{ content: "\\f10c"; } `,
  "award-winning":` &:before{ content: "\\f10d"; } `,
  "award":` &:before{ content: "\\f10e"; } `,
  "back":` &:before{ content: "\\f10f"; } `,
  "biodynamic":` &:before{ content: "\\f110"; } `,
  "birthday-bonus":` &:before{ content: "\\f111"; } `,
  "blend-type":` &:before{ content: "\\f112"; } `,
  "bottle-glass":` &:before{ content: "\\f113"; } `,
  "bottle":` &:before{ content: "\\f114"; } `,
  "boxes":` &:before{ content: "\\f115"; } `,
  "calendar-clock":` &:before{ content: "\\f116"; } `,
  "calendar":` &:before{ content: "\\f117"; } `,
  "camera":` &:before{ content: "\\f118"; } `,
  "caps-lock":` &:before{ content: "\\f119"; } `,
  "cart":` &:before{ content: "\\f11a"; } `,
  "case":` &:before{ content: "\\f11b"; } `,
  "check":` &:before{ content: "\\f11c"; } `,
  "Checkbox":` &:before{ content: "\\f11d"; } `,
  "cheers":` &:before{ content: "\\f11e"; } `,
  "chevron-down":` &:before{ content: "\\f11f"; } `,
  "chevron-left":` &:before{ content: "\\f120"; } `,
  "chevron-right":` &:before{ content: "\\f121"; } `,
  "chevron-up":` &:before{ content: "\\f122"; } `,
  "chocolate":` &:before{ content: "\\f123"; } `,
  "choice-check":` &:before{ content: "\\f124"; } `,
  "choice-uncheck":` &:before{ content: "\\f125"; } `,
  "circle-chevron-left":` &:before{ content: "\\f126"; } `,
  "circle-chevron-right":` &:before{ content: "\\f127"; } `,
  "clear-circle":` &:before{ content: "\\f128"; } `,
  "close":` &:before{ content: "\\f129"; } `,
  "convenient-delivery":` &:before{ content: "\\f12a"; } `,
  "cork-screw":` &:before{ content: "\\f12b"; } `,
  "coupons":` &:before{ content: "\\f12c"; } `,
  "credit-card":` &:before{ content: "\\f12d"; } `,
  "customer-services":` &:before{ content: "\\f12e"; } `,
  "customize":` &:before{ content: "\\f12f"; } `,
  "customized-selections":` &:before{ content: "\\f130"; } `,
  "delete":` &:before{ content: "\\f131"; } `,
  "diamond":` &:before{ content: "\\f132"; } `,
  "discover":` &:before{ content: "\\f133"; } `,
  "dot-menu":` &:before{ content: "\\f134"; } `,
  "dots-menu":` &:before{ content: "\\f135"; } `,
  "droplet":` &:before{ content: "\\f136"; } `,
  "edit":` &:before{ content: "\\f137"; } `,
  "email":` &:before{ content: "\\f138"; } `,
  "envelope":` &:before{ content: "\\f139"; } `,
  "expand":` &:before{ content: "\\f13a"; } `,
  "eye":` &:before{ content: "\\f13b"; } `,
  "facebook":` &:before{ content: "\\f13c"; } `,
  "file-user":` &:before{ content: "\\f13d"; } `,
  "filter":` &:before{ content: "\\f13e"; } `,
  "flexibility":` &:before{ content: "\\f13f"; } `,
  "free-delivery":` &:before{ content: "\\f140"; } `,
  "gift":` &:before{ content: "\\f141"; } `,
  "glass":` &:before{ content: "\\f142"; } `,
  "glasses":` &:before{ content: "\\f143"; } `,
  "google-pay":` &:before{ content: "\\f144"; } `,
  "grape":` &:before{ content: "\\f145"; } `,
  "green-tick":` &:before{ content: "\\f146"; } `,
  "hamburger-menu":` &:before{ content: "\\f147"; } `,
  "hand-truck":` &:before{ content: "\\f148"; } `,
  "heart-solid":` &:before{ content: "\\f149"; } `,
  "heart":` &:before{ content: "\\f14a"; } `,
  "help-circle":` &:before{ content: "\\f14b"; } `,
  "hide-password":` &:before{ content: "\\f14c"; } `,
  "home":` &:before{ content: "\\f14d"; } `,
  "homepage-customisation":` &:before{ content: "\\f14e"; } `,
  "information-circle":` &:before{ content: "\\f14f"; } `,
  "instagram":` &:before{ content: "\\f150"; } `,
  "kosher":` &:before{ content: "\\f151"; } `,
  "label-scanner":` &:before{ content: "\\f152"; } `,
  "last-sold-bg":` &:before{ content: "\\f153"; } `,
  "ligntning-solid":` &:before{ content: "\\f154"; } `,
  "linked-in":` &:before{ content: "\\f155"; } `,
  "live-chat":` &:before{ content: "\\f156"; } `,
  "location":` &:before{ content: "\\f157"; } `,
  "mastercard":` &:before{ content: "\\f158"; } `,
  "menu":` &:before{ content: "\\f159"; } `,
  "minus":` &:before{ content: "\\f15a"; } `,
  "money-back-apac":` &:before{ content: "\\f15b"; } `,
  "money-back":` &:before{ content: "\\f15c"; } `,
  "mute":` &:before{ content: "\\f15d"; } `,
  "no-obligation":` &:before{ content: "\\f15e"; } `,
  "offset-carbon":` &:before{ content: "\\f15f"; } `,
  "organic":` &:before{ content: "\\f160"; } `,
  "packaging-pioneer":` &:before{ content: "\\f161"; } `,
  "padlock":` &:before{ content: "\\f162"; } `,
  "pause":` &:before{ content: "\\f163"; } `,
  "paypal":` &:before{ content: "\\f164"; } `,
  "phone-solid":` &:before{ content: "\\f165"; } `,
  "phone":` &:before{ content: "\\f166"; } `,
  "pioneer":` &:before{ content: "\\f167"; } `,
  "placeholder":` &:before{ content: "\\f168"; } `,
  "play-video":` &:before{ content: "\\f169"; } `,
  "play":` &:before{ content: "\\f16a"; } `,
  "print":` &:before{ content: "\\f16b"; } `,
  "question-circle":` &:before{ content: "\\f16c"; } `,
  "radio-selected":` &:before{ content: "\\f16d"; } `,
  "radio":` &:before{ content: "\\f16e"; } `,
  "red-alert-circle-filled":` &:before{ content: "\\f16f"; } `,
  "restaurant-dishes":` &:before{ content: "\\f170"; } `,
  "rossette":` &:before{ content: "\\f171"; } `,
  "sale-tag":` &:before{ content: "\\f172"; } `,
  "sale":` &:before{ content: "\\f173"; } `,
  "satisfaction-guarantee":` &:before{ content: "\\f174"; } `,
  "saving":` &:before{ content: "\\f175"; } `,
  "savings":` &:before{ content: "\\f176"; } `,
  "search":` &:before{ content: "\\f177"; } `,
  "share":` &:before{ content: "\\f178"; } `,
  "star-solid":` &:before{ content: "\\f179"; } `,
  "star":` &:before{ content: "\\f17a"; } `,
  "stwc-blog":` &:before{ content: "\\f17b"; } `,
  "subscribe-save":` &:before{ content: "\\f17c"; } `,
  "sustainable":` &:before{ content: "\\f17d"; } `,
  "target":` &:before{ content: "\\f17e"; } `,
  "thumbs-down-solid":` &:before{ content: "\\f17f"; } `,
  "thumbs-down":` &:before{ content: "\\f180"; } `,
  "thumbs-up-solid":` &:before{ content: "\\f181"; } `,
  "thumbs-up":` &:before{ content: "\\f182"; } `,
  "tick-circle-solid":` &:before{ content: "\\f183"; } `,
  "tick-circle":` &:before{ content: "\\f184"; } `,
  "ticket":` &:before{ content: "\\f185"; } `,
  "trending":` &:before{ content: "\\f186"; } `,
  "truck-solid":` &:before{ content: "\\f187"; } `,
  "truck":` &:before{ content: "\\f188"; } `,
  "trust":` &:before{ content: "\\f189"; } `,
  "twitter":` &:before{ content: "\\f18a"; } `,
  "unidentified-country":` &:before{ content: "\\f18b"; } `,
  "units":` &:before{ content: "\\f18c"; } `,
  "unlimited-sale":` &:before{ content: "\\f18d"; } `,
  "unlimited-save":` &:before{ content: "\\f18e"; } `,
  "unlimited-truck":` &:before{ content: "\\f18f"; } `,
  "unlimited":` &:before{ content: "\\f190"; } `,
  "unltCheckIcon":` &:before{ content: "\\f191"; } `,
  "user":` &:before{ content: "\\f192"; } `,
  "vegan":` &:before{ content: "\\f193"; } `,
  "vegetarian":` &:before{ content: "\\f194"; } `,
  "views":` &:before{ content: "\\f195"; } `,
  "vinny":` &:before{ content: "\\f196"; } `,
  "visa":` &:before{ content: "\\f197"; } `,
  "warning":` &:before{ content: "\\f198"; } `,
  "wine-colour":` &:before{ content: "\\f199"; } `,
  "wine-glass":` &:before{ content: "\\f19a"; } `,
  "world":` &:before{ content: "\\f19b"; } `,
  "x-twitter":` &:before{ content: "\\f19c"; } `,
  "youtube":` &:before{ content: "\\f19d"; } `,
  "zoom":` &:before{ content: "\\f19e"; } `,
}